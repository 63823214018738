import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import telling from "../../images/telling.png"

const tellingPlatvorm = () => (
  <Layout>
    <SEO title="Tellingplatvormid" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>TELLING-PLATVORMID</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/platvormid/platvormid/">
          <div className="epo antistaatilised">
            <h2>Platvormid</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/platvormid/kaarjalgadega/">
          <div className="epo epohoordemass">
            <h2>Käärjalgadega platvormid</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link to="/platvormid/motoriseeritud/">
          <div className="epo isetasanduv-epomasskate">
            <h2>Mootoriga platvormid</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link
          to="/platvormid/tellingplatvormid/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo treppidele">
            <h2>Telling-platvormid</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/platvormid/raam/">
          <div className="epo dekoratiivporandad">
            <h2>Lavade raamid</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
      </div>

      <div className="antistaatilised-wrapper">
        <div className="antistaatilised-text">
          <h3>Telling-platvormid</h3>
          <p>
            Pistiku tugipostiga kinnitus võimaldab kasutada kõiki müügil olevaid
            pistikühendussüsteeme alates 48,3 mm ümmargustest torudest kuni
            ruudukujuliste 55 x 55 mm või 60 x 60 mm süsteemideni.
            Pistikühenduse jalgade vajutamist saab valikuliselt teostada
            ekstsentriliste või reguleeritavate kruvide abil. Tänu
            ilmastikukindlale pinnale sobib ERGO | Lay suurepäraselt nii sise-
            kui ka välisüritusteks.
          </p>
        </div>
        <div className="subpage-img platvorm-img">
          <img src={telling} alt="Telling-platvorm"></img>
        </div>
      </div>
    </div>
  </Layout>
)

export default tellingPlatvorm
